<template>
  <div>
    <v-scale-screen :fullScreen="false" width="1920" height="1080">
      <div class="newIndScreen">
        <div class="videobg">
          <div class="overlay"></div>
          <video
            autoplay
            muted
            loop
            objectFit="cover"
            style="width: 100%; height: 100%; object-fit: cover"
          >
            <source src="@/assets/img/bg.mp4" type="video/mp4" />
          </video>
        </div>
        <!-- 顶部 -->
        <div class="top">
          <div class="top_title">农业数据分析</div>
          <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
        </div>

        <div class="cont" style="height: 1030px">
          <!-- 左中 -->
          <div class="contLeft">
            <div class="nav_left">
              <div
                class="flex_space flex_align"
                style="width: 403px; margin-left: 46px"
              >
                <div
                  class="nav_left_item"
                  v-for="(item, index) in leftList"
                  :key="index"
                  @click="showMode(item, index)"
                >
                  <div class="nav_left_img">
                    <img :src="item.icon" style="width: 100%; height: 100%" />
                  </div>
                  <div class="nav_left_name">{{ item.name }}</div>
                  <div class="nav_left_num">{{ item.num || 0 }}</div>
                </div>
              </div>
              <img
                src="../assets/img/return.png"
                style="
                  height: 70px;
                  width: 70px;
                  margin-left: 8px;
                  z-index: 20;
                  display: block;
                "
                @click="goHome()"
              />
            </div>
            <!-- 种植 -左侧 -->
            <div class="nsLeft">
              <div class="">
                <div
                  class="flex_space flex_center_align"
                  style="margin-left: 24px"
                >
                  <div class="lc_title">种植</div>
                </div>
                <div class="line"></div>
                <div class="panel1">
                  <div class="plant_box_top">
                    <div class="plant_box_top_left">
                      <div class="" style="">
                        <div class="panel_title">主要品种</div>
                      </div>
                      <div class="p_new_line" style="width: 168px"></div>
                      <div class="" style="margin-top: 10px">
                        <div  v-for="(item, index) in plantData.typeData" :key="index">{{item}}</div>
                       
                      </div>
                    </div>
                    <div class="plant_box_top_left">
                      <div class="" style="">
                        <div class="panel_title">代表企业</div>
                      </div>
                      <div class="p_new_line" style="width: 168px"></div>
                      <div class="" style="margin-top: 10px"  v-for="(item, index) in plantData.DBQY" :key="index">
                        <div @click="showDelMode(item)" style="cursor: pointer;">{{ item.title }}</div>
                        <!-- <div>古香杂食店</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="plant_box_center">
                    <div style="margin-left: 24px">近三年总产值</div>
                    <div
                      class="plant_box_center_bottom"
                      style="margin-right: 16px"
                    >
                      <span>{{ plantData.price }}</span
                      ><span>万元</span>
                    </div>
                  </div>
                  <div
                    style="margin: 0 16px"
                    v-for="(item, index) in plantData.morelines"
                    :key="index"
                  >
                    <MoreLineChart v-bind="item"></MoreLineChart>
                  </div>
                </div>
              </div>
            </div>
            <!-- 养殖-左侧 -->
            <div class="nsLeft" style="margin-top: 30px">
              <div class="">
                <div
                  class="flex_space flex_center_align"
                  style="margin-left: 24px"
                >
                  <div class="lc_title">养殖</div>
                </div>
                <div class="line"></div>
                <div class="panel1">
                  <div class="plant_box_top">
                    <div class="plant_box_top_left">
                      <div class="" style="">
                        <div class="panel_title">主要品类</div>
                      </div>
                      <div class="p_new_line" style="width: 168px"></div>
                      <div class="" style="margin-top: 10px">
                        <div>鸡</div>
                        <div>猪</div>
                        <div>牛</div>
                        <div>羊</div>
                      </div>
                    </div>
                    <div class="plant_box_top_left">
                      <div class="" style="">
                        <div class="panel_title">代表养殖户</div>
                      </div>
                      <div class="p_new_line" style="width: 168px"></div>
                      <div class="" style="margin-top: 10px">
                        <!-- <div>鑫宇种植家庭农场</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="plant_box_center">
                    <div style="margin-left: 24px">近三年总产值</div>
                    <div
                      class="plant_box_center_bottom"
                      style="margin-right: 16px"
                    >
                      <span>{{ breedData.price }}</span
                      ><span>万元</span>
                    </div>
                  </div>
                  <div
                    style="margin: 0 16px"
                    v-for="(item, index) in breedData.morelines"
                    :key="index"
                  >
                    <MoreLineChart v-bind="item"></MoreLineChart>
                  </div>

                  <div class="left-center" style="margin-top: 20px">
                    <div
                      class="flex_space flex_center_align"
                      style="margin-left: 24px"
                    >
                      <div class="panel_title">养殖品类明细</div>
                    </div>
                    <div
                      class="p_new_line"
                      style="margin-left: 24px; width: 308px"
                    ></div>
                    <div class="panel">
                      <div
                        class="plant_box_center"
                        style="margin: 5px 0 0 24px; cursor: pointer"
                        v-for="(item, index) in breedData.checkMode"
                        :key="index"
                        @click="openBreedDetail(item, breedData.checkMode)"
                      >
                        <!-- @click="changeMap(it, items.elementValue.elementType) -->
                        <div class="point_box">
                          <div class="point"></div>
                          <div style="margin-left: 10px">{{ item.title }}</div>
                        </div>
                        <img src="../assets/img/5.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contCenter">
            <div class="nscenter">
              <!-- 地图 -->
              <div class="mapCss mapCity">
                <div ref="mapBox" style="width: 100%; height: 100%"></div>
              </div>
            </div>
            <div class="footer_btn" @click="goAlldata()">
              <img
                style="disllay: block; height: 32px; width: 32px"
                src="@/assets/img/tb1.png"
                alt=""
              />
              <span>总览</span>
            </div>
          </div>

          <!-- 右 -->
          <div class="contRight">
            <div class="nsright" style="margin-top: 121px">
              <div class="left-center flex_space flex_center_align">
                <div
                  class="flex_space flex_center_align"
                  style="margin-left: 24px"
                >
                  <div class="lc_title">土地使用</div>
                </div>
                <!-- <img class="lc_right" src="../assets/img/rarrow.png" /> -->
              </div>
              <div class="line"></div>
              <div class="panel1" style="height: 860px; overflow-y: scroll">
                <div class="plant_box_center">
                  <div style="margin-left: 24px">区域面积</div>
                  <div
                    class="plant_box_center_bottom"
                    style="margin-right: 16px"
                  >
                    <span>{{ landData.num }}</span
                    ><span>平方公里</span>
                  </div>
                </div>
                <div class="panel_cun_new">
                  <div class="ageVue">
                    <div class="echartsBg">
                      <div class="age_num">占比</div>
                    </div>
                    <div
                      class="circle"
                      ref="agroCircle"
                      style="
                        width: 100%;
                        height: 100%;
                        border: 1px solid transparent;
                        -webkit-tap-highlight-color: transparent;
                        user-select: none;
                      "
                    ></div>
                    <div class="echartsLeg">
                      <div class="flex_start">
                        <div class="circle" style="border-color: #9524cb"></div>
                        <div>
                          <div class="panel_cun_name">总耕地</div>
                          <div class="panel_cun_num">{{ gd }}%</div>
                        </div>
                      </div>
                      <div class="flex_start">
                        <div class="circle" style="border-color: #f9b814"></div>
                        <div>
                          <div class="panel_cun_name">有林地</div>
                          <div class="panel_cun_num">{{ ld }}%</div>
                        </div>
                      </div>

                      <div class="flex_start">
                        <div class="circle" style="border-color: #4db9a5"></div>
                        <div>
                          <div class="panel_cun_name">其他</div>
                          <div class="panel_cun_num">{{ other }}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="colum_box">
                  <div class="colum_top">
                    <div v-for="(item, index) in 1" :key="index" style="height: 140px;">
                      <MoreLineChart v-bind="item"></MoreLineChart>
                    </div>
                    <div v-for="(item, index) in landData.bar" :key="index">
                      <ColumChart v-bind="item"></ColumChart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewMode ref="newMode"></NewMode>
      <CunMode ref="cunMode"></CunMode>
      <!-- <TFooter :tabList="tabList" @tab="handlerTab"></TFooter> -->
    </v-scale-screen>
  </div>
</template>
<script>
import { mapOption, barOptions, newlineOption, newMapOption } from '../utils/options.js'
import NewMode from '@/components/newMode.vue'
import MoreLineChart from '@/components/chart/MoreLineChart.vue'
import ColumChart from '@/components/chart/ColumChart.vue'
import { getID } from '@/utils/index.js'
import CunMode from '@/components/industryMode'
import TFooter from '@/components/footer.vue'

import { vipCarDateList, vipCarList, getIndexStatistics, postLogin, getDangIndex, getHomeDetail, getOthers, getLineClass, getBankList, articleInfoList, searchReadNums } from '@/api/api.js'

const mapData = require('../utils/newcun.json')
import * as echarts from 'echarts'
import { mapdata, geoCoordMap, coordinates } from '../utils/mapCenterPoint'

export default {
  components: {
    ColumChart,
    NewMode,
    MoreLineChart,
    CunMode,
    TFooter
  },
  data() {
    return {
      userInfo: {},
      // 农业
      nyInfo: {},
      leftList: [
        {
          name: '民众心声',
          icon: require('@/assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('@/assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('@/assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('@/assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('@/assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      zpajList: [{}, {}, {}, {}],
      total: {
        familyNum: 0,
        residentNum: 0,
        adminUserNum: 0,
        oftenNum: 0,
        foreignNum: 0,
        flowNum: 0,
        rangerNum: 0,
        impatientNum: 0
      },
      //种植
      plantData: {
        price: '',
        morelines: [],
        DBQY: [],
        typeData: []
      },
      //种植
      breedData: {
        price: '',
        morelines: [],
        checkMode: [],
        DBQY: []
      },
      //土地
      landData: {
        num: '',
        pie: {},
        morelines: [],
        bar: [],
        pieObj: {
          gd: '',
          ld: '',
          other: '',
          all: ''
        }
      },
      nerwstimer: ''
    }
  },
  computed: {
    gd() {
      return (Number(this.landData.pieObj.gd) * 100 / (Number(this.landData.pieObj.all) * 100) * 100).toFixed(2)
    },
    ld() {
      return (Number(this.landData.pieObj.ld) * 100 / (Number(this.landData.pieObj.all) * 100) * 100).toFixed(2)
    },
    other() {
      return (Number(this.landData.pieObj.other) * 100 / (Number(this.landData.pieObj.all) * 100) * 100).toFixed(2)
    }
  },
  methods: {
    goAlldata() {
      this.$router.replace('/industry')
    },

    //弹窗
    openBreedDetail(item, type) {
      console.log('item', item, type)

      // console.log(item)
      // if (type == 'checkMode') {
      item.checked = !item.checked
      let data = item.list[0]
      data.itemtype = type
      this.$refs.cunMode.initData(data)
      // } else {
      //   item.checked = !item.checked
      //   if (item.checked) {
      //     this.clearMarker(item)
      //     this.changerow = item
      //     let data = item.point || item.list
      //     this.pointData = []
      //     this.setMapSetting(data)
      //     this.initBorder()
      //   } else {
      //     this.clearMarker()
      //     this.setCunMap('ls')
      //     this.initBorder()
      //     this.changerow = ''
      //   }
      // }
    },

    showMode(item, index) {
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item, index)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item, index)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }

      this.clearRead(item.ptype)
    },
    vipCarList(item, index) {
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data, index) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item, index) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    goHome() {
      this.$router.push('/integrated')
      setTimeout(function() {
        window.location.reload()
      }, 30)
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      this.initMap()
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      // map.clear()
      // map.setOption(newMapOption)
    },

    //饼图
    drowSerCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#9524CB', '#F9B914', '#4DB9A5'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['耕地', '林地', '其他']
        },
        series: [
          {
            name: '占比',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            // data:[],
            data: data.pieData
          }
        ]
      }

      let cricle = echarts.init(this.$refs.agroCircle, null, {
        renderer: 'svg'
      })
      cricle.clear()
      cricle.setOption(circleOption)
    },

    submitForm(formName) {
      let that = this
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      this.userInfo = {}
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            sessionStorage.setItem('token', res.model.tokenValue)
            this.userInfo = res.model
            that.$nextTick(() => {
              // that.getHomeDetail(this.userInfo.regionId);
              that.getHome(this.userInfo.regionId, '农业')
            })
          } else {
            that.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({ villageId: regionId }).then(res => {
        if (res.code == 0) {
          // this.totalNums = res.model.memberCount
          // this.$nextTick(() => {
          //   this.drowFunction(res.model)
          // })
        }
      })
    },
    filterArr(list) {
      let arr = list
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].detailList && arr[i].detailList.length) {
          arr[i].detailList.map(item => {
            if (item?.elementValue) {
              item.elementValue = JSON.parse(item.elementValue)
              if (item.elementValue.elementType == 'checkBox') {
                // 多选
                item.elementValue.checkList.forEach(el => {
                  el.id = getID()
                })
              }
              if (item.elementValue.elementType == 'media') {
                // 带有监控
                item.elementValue.list = []
                item.elementValue.list.push({
                  icon: 'dapeng',
                  lat: item.elementValue.lat,
                  lgt: item.elementValue.lgt,
                  name: item.elementValue.title,
                  articleContent: item.elementValue.articleContent
                })
              }
              if (item.elementValue.elementType == 'morelines') {
                //多折线
                item.elementValue.legend = []
                item.elementValue.yAxisData.map(it => {
                  item.elementValue.legend.push(it.name)
                  it.data = []
                  if (it.tagData.length) {
                    it.tagData.map(ii => {
                      if (ii?.num != undefined) {
                        it.data.push(ii.num)
                      }
                    })
                  }
                })
              }
            }
          })
        }
      }
      return arr
    },

    getHome(regionId, type) {
      let data = {
        regionId: regionId,
        tabName: type
      }
      getOthers(data).then(res => {
        let arr = this.filterArr(res.model)
        console.log('arr', arr)
        if (!arr) return
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.position == 'left-top') {
            let arr = item.detailList
            for (let j = 0; j < arr.length; j++) {
              const element = arr[j]
              if (element.elementName == '总产值') {
                this.plantData.price = element.elementValue.value
              }
              if (element.elementType == 'morelines') {
                this.plantData.morelines.push(element.elementValue)
              }
              if (element.elementName == '代表企业' && element.elementType == 'checkBox') {
                this.plantData.DBQY = element.elementValue.checkList
              }
              if (element.elementName == '主要品类') {
                this.plantData.typeData = element.elementValue.value.split('、')
                // console.log('qqwwwwwww',element);
                // console.log(' this.plantData.typeData', this.plantData.typeData);
              }
            }
          }
          if (item.position == 'left-bottom') {
            let arr = item.detailList
            for (let k = 0; k < arr.length; k++) {
              const element = arr[k]
              if (element.elementName == '近三年总产值') {
                this.breedData.price = element.elementValue.value
              }
              if (element.elementType == 'morelines') {
                this.breedData.morelines.push(element.elementValue)
              }
              if (element.elementType == 'checkMode') {
                this.breedData.checkMode = element.elementValue.checkList
                // console.log('e===============>', element)
              }
              // if (element.elementName == '代表企业') {
              //   this.breedData.DBQY.push(element.elementValue)
              // }
            }
          }

          if (item.position == 'right') {
            let arr = item.detailList
            for (let o = 0; o < arr.length; o++) {
              const element = arr[o]
              if (element.elementName == '区域面积') {
                this.landData.num = element.elementValue.value
              }
              if (element.elementType == 'pie') {
                this.landData.pie = element.elementValue
                this.landData.pieObj.gd = Number(element.elementValue.pieData[0].value)
                this.landData.pieObj.ld = Number(element.elementValue.pieData[1].value)
                this.landData.pieObj.other = Number(element.elementValue.pieData[2].value)
                this.landData.pieObj.all = this.landData.pieObj.gd + this.landData.pieObj.ld + this.landData.pieObj.other
                this.drowSerCircle(element.elementValue)
              }
              if (element.elementType == 'morelines') {
                this.landData.morelines.push(element.elementValue)
              }
              if (element.elementType == 'bar') {
                this.landData.bar.push(element.elementValue)
              }
            }
          }
        }
        // console.log(
        //   "this.land==========>",
        //   this.landData,
        //   this.breedData,
        //   this.plantData
        // );
      })
    },

    searchNews() {
      this.nerwstimer = setInterval(() => {
        this.getReadnums()
      }, 10000) // 滚动速度
    },
    showMessage(content) {
      // this.content = content
      // this.show = true
      if (content) {
        let item = this.leftList.filter(item => {
          return item.type == content.type
        })[0]
        let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          item.num = item.num + 1
          this.$set(this.leftList, index, item)
          // this.content = {}
          // this.show = false
        }, 10000) // 3秒后隐藏消息
      }
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            //贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
        }
      })
    },
    showDelMode(data) {
      if (!data?.list[0].articleContent) {
        return
      }
      let obg = data.list[0]

      this.$refs.cunMode.initData(obg)
    }
  },
  created() {
    this.submitForm()
  },
  mounted() {
    setTimeout(() => {
      // this.$nextTick(() => {
      this.initChart()
      // })
    }, 2000)
    this.getReadnums()

    //消息推送
    this.searchNews()
  },
  destroyed() {
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'JDLangZhengTi';
  src: url('../assets/fonts/JDLangZhengTi.ttf') format('truetype');
}
@font-face {
  font-family: 'ShiShangZhongHeiJianTi';
  src: url('../assets/fonts/ShiShangZhongHeiJianTi.ttf') format('truetype');
}

.plant_box_center {
  height: 61px;
  width: 356px;
  margin: 27px auto 0;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(28, 45, 92, 0.49) 0%, rgba(28, 45, 92, 0.04) 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .point_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }
  .point {
    height: 6px;
    width: 6px;
    box-shadow: 0px 0px 13px 1px #ffffff;
    background-color: #ffffff;
  }

  .plant_box_center_bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    span:nth-child(1) {
      height: 36px;
      font-family: ShiShangZhongHeiJianTi;
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
    }
    span:nth-child(2) {
      height: 22px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #0099ff;
      margin-left: 6px;
    }
  }
}
.plant_box_top {
  // height: 120px;
  width: 356px;
  margin: 23px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .plant_box_top_left {
    width: 168px;
  }
}

.nav_left {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  &_item {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }
  &_img {
    width: 59px;
    height: 59px;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
  }
  &_name {
    font-family: JDLangZhengTi;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
  &_num {
    position: absolute;
    top: -6px;
    right: 8px;
    z-index: 99;
    background: #ff6b00;
    border-radius: 15px;
    text-align: center;
    font-family: JDLangZhengTi;
    font-size: 8px;
    font-weight: 400;
    min-width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
  }
}

.colum_box {
  width: 324px;
  // height: 598px;
  margin: 1px auto 0;
  // background: #0d1836;
}

.info_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 56px 0 67px 43px;
  // background-color: red;
  .info_box_num {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;

    .title {
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
    }
    .num {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      div:nth-child(1) {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
      }

      div:nth-child(2) {
        font-family: Microsoft YaHei;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #0099ff;
      }
    }
  }
}

// 公共
.lc_title {
  font-family: JDLangZhengTi;
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  margin-left: 21px;
  position: relative;
}

.lc_title::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 32px;
  background: #0099ff;
  box-shadow: 0px 0px 13px 1px #0099ff;
  position: absolute;
  top: 0;
  left: -21px;
}
.lc_right {
  margin-right: 44px;
}

.panel1::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.panel1 {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  // overflow-x: hidden;
  // overflow-y: auto;
}
.panel1 {
  height: 385px;
  width: 400px;
  // height: auto;
  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0) 100%);
  overflow-y: scroll;
  // clear: both;
  .panel_top {
    padding: 27px 0 0 22px;
  }

  .panel_title {
    font-family: JDLangZhengTi;
    font-size: 20px;
    font-weight: 400;
    color: #0099ff;
    margin-bottom: 17px;
  }
  .p_line {
    width: 319px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
  .p_new_line {
    width: 332px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
  .p_font {
    font-size: 12px;
    font-weight: 400;
  }
}
// .panel {
//   height: 435px;
//   width: 400px;
//   // height: auto;
//   background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0) 100%);
//   overflow: hidden;
//   // clear: both;
//   .panel_top {
//     padding: 27px 0 0 22px;
//   }

//   .panel_title {
//     font-family: JDLangZhengTi;
//     font-size: 20px;
//     font-weight: 400;
//     color: #0099ff;
//     margin-bottom: 17px;
//   }
//   .p_line {
//     width: 319px;
//     height: 2px;
//     background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
//   }
//   .p_new_line {
//     width: 332px;
//     height: 2px;
//     background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
//   }
//   .p_font {
//     font-size: 12px;
//     font-weight: 400;
//   }
// }
.line {
  background: linear-gradient(90deg, #0780ae 0%, rgba(7, 128, 174, 0) 100%);
  height: 2px;
  margin-top: 21px;
}
.newIndScreen {
  width: 100%;
  height: 100%;
  // background-image: url("../assets/img/bg1.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  color: #fff;

  .videobg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(41, 6, 22, 0.8), rgba(8, 16, 64, 0.8));
  }
  .top_title {
    font-family: JDLangZhengTi;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: #c4e6fe;
    text-shadow: #0099ff 0px 0px 15px;
    width: 1471.5px;
    height: 83px;
    line-height: 83px;
    margin: 0 auto;
    background-image: url('../assets/img/itop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .top_title_people {
    width: 253px;
    height: 47px;
    margin: -10px auto 0;
    background-image: url('../assets/img/itop2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-shadow: #0099ff 0px 0px 15px;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 13px;
      margin-left: 25px;
      margin-top: 5px;

      .left {
        height: 40px;
        width: 40px;
      }

      .right {
        height: 13px;
        font-family: Microsoft YaHei;
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
        color: rgba(255, 255, 255, 1);
      }
    }

    .bottom {
      height: 22px;
      line-height: 22px;
      font-family: ShiShangZhongHeiJianTi;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(255, 255, 255, 1);
      margin-right: 35px;
      margin-top: 5px;
      // box-shadow: 0px 0px 10px 1px rgba(0, 153, 255, 1);
    }
  }
  .cont {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: -84px;
    position: relative;
  }
  .nav_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    // left: 46px;
    top: 0;
    &_item {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &_img {
      width: 59px;
      height: 59px;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
    }
    &_name {
      font-family: JDLangZhengTi;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }
  }
  .nav_right {
    position: absolute;
    right: 81px;
    top: 0;
    width: 410px;
  }
  .nsLeft {
    width: 400px;
    // height: 414px;
    margin-top: 121px;
    .left-center .panel_cun {
      padding: 60px 25px 72px 28px;
      .panel_cun_box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      .circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 5px solid #ffffff;
      }
      .panel_cun_name {
        font-size: 12px;
        line-height: 15.96px;
        margin-left: 6px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-left: 6px;
      }
      .ageVue {
        width: 285px;
        height: 195px;
        position: relative;
        //overflow: hidden;
        margin-top: 40px;
        .echartsBg {
          position: absolute;
          width: 78%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .age_img {
          width: 42px;
          height: 42px;
        }
        .age_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 32px;
          font-weight: 400;
          line-height: 38px;
          // letter-spacing: 0em;
          // text-align: center;
          text-shadow: 0px 1px 10px #0099ff;
        }
      }
    }
    .line_box {
      width: 345px;
      height: 194px;
      margin: 30px 0 22px 13px;
    }
  }
  .contCenter {
    position: relative;

    .footer_btn {
      position: absolute;
      height: 70px;
      width: 100px;
      background-color: red;
      bottom: 20px;
      right: 20px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(180deg, rgba(0, 50, 100, 0.8), #0099ff);
      border: 1px solid #0099ff;
      border-radius: 10px;
    }
  }

  .nscenter {
    width: 1103px;
    margin-top: 147px;
    .mapCity {
      width: 710px;
      margin: 0 auto;
      height: 507px;
    }
    .center_bottom {
      display: flex;
      align-items: flex-start;
      .center_bleft {
        flex: 1.5;
        margin-right: 27px;
        margin-left: 3px;
      }
      .center_nright {
        flex: 1;
        margin-right: 24px;
      }
    }
  }
  .nsright {
    width: 400px;
    height: 435px;
    margin-top: 119px;
    .right_item {
      width: 354px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin: 14px 0 0 37px;
      &_box {
        flex: 1;
        margin-bottom: 21px;
        .item_title {
          margin-bottom: 8px;
        }
      }
    }
    .newChar {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-shadow: 0px 1px 10px #0099ff;
    }
  }
}
.muNum {
  font-family: ShiShangZhongHeiJianTi;
  font-size: 30px;
  font-weight: 400;
  text-shadow: 0px 1px 10px #0099ff;
}
.mu {
  color: #0099ff;
}
//
.panel_cun_new {
  width: 355px;
  height: 142px;
  padding: 0px 0px 0px 34px;
  .panel_cun_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 5px solid #ffffff;
  }
  .panel_cun_name {
    font-size: 12px;
    line-height: 15.96px;
    margin-left: 6px;
  }
  .panel_cun_num {
    font-family: ShiShangZhongHeiJianTi;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-left: 6px;
  }
  .ageVue {
    width: 254px;
    height: 120px;
    position: relative;
    // overflow: hidden;
    margin-top: 38px;
    .echartsBg {
      position: absolute;
      width: 78%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .age_img {
      width: 42px;
      height: 42px;
    }
    .age_num {
      font-family: ShiShangZhongHeiJianTi;
      font-size: 12px;
      font-weight: 400;
      line-height: 38px;
      // letter-spacing: 0em;
      // text-align: center;
      text-shadow: 0px 1px 10px #0099ff;
    }
    .echartsLeg {
      position: absolute;
      right: -30px;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .panel_cun_name {
        font-size: 14px;
        line-height: 18.48px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
        text-shadow: 0px 1px 10px #0099ff;
        margin-top: 4px;
      }
    }
  }
}
.nav_right .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #ceb77b !important;
  height: 0.15rem !important;
}
.nav_right .el-table .el-table__cell {
  padding: 0 !important;
  height: 0.15rem !important;
  font-size: 0.12rem;
}
.nav_right .el-table thead {
  color: #fff !important;
  font-size: 0.12rem !important;
}
.nav_right > .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
.nav_right .el-table tr {
  color: #fff !important;
  background: transparent !important;
}

/* 用来设置当前页面element全局table 选中某行时的背景色*/
.nav_right .el-table__body tr.current-row > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
.nav_right .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.nav_right .el-table__body-wrapper {
  overflow: hidden !important;
}
.nav_right .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none !important;
}
.nav_right .el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
.nav_right .el-table .cell {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.nav_right .el-table::before {
  height: 0 !important;
}
</style>